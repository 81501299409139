<template>
	<a-card :bordered="false" class="header-solid h-full" :bodyStyle="{ padding: '24px' }">
		<template #title>
			<a-row type="flex" align="middle">
				<a-col :span="24" :md="12">
					<h6>经典案例</h6>
				</a-col>
				<a-col :span="24" :md="12" style="display: flex; align-items: center; justify-content: flex-end">
					<a-radio-group v-model="projectHeaderBtns" size="small">
						<a-radio-button value="all">全部</a-radio-button>
						<a-radio-button value="other">其他</a-radio-button>
					</a-radio-group>
				</a-col>
			</a-row>
		</template>
		<div class="body_box">
			<div class="list_box">
				<div class="item" v-for="(item, index) in caseList" :key="'case_' + item.id">
					<h6 class="c-line-1">{{ item.title }}</h6>
					<p class="c-line-1">{{ item.description }}</p>
					<div class="info">
						<div class="left">
							<a-avatar size="small" :src="item.user_avatar" />
							<span>{{ item.user_name }}</span>
						</div>
						<div class="right">{{ item.updatetime }}</div>
					</div>
				</div>
			</div>
		</div>
	</a-card>
</template>

<script>
	export default {
		props: {},
		data() {
			return {
				// Active button for the "Projects" table's card header radio button group.
				projectHeaderBtns: "all",
				caseList: []
			}
		},
		created() {
			this.getCaseList()
		},
		methods: {
			getCaseList() {
				this.$http("case.list", {
					page: 1,
					pagesize: 3
				}).then(res => {
					if (res.code === 1) {
						this.caseList = res.data
					}
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.body_box {
		.list_box {
			.item {
				background: #fafafa;
				border: 1px solid #f5f5f5;
				border-radius: 12px;
				padding: 12px;
				margin-bottom: 12px;

				&:last-child {
					margin-bottom: 0;
				}

				p {
					color: #8c8c8c;
				}

				.info {
					display: flex;
					align-items: center;
					justify-content: space-between;
					color: #8c8c8c;
					.left {
						color: #141414;
						display: flex;
						align-items: center;

						span {
							margin-left: 8px;
						}
					}

					.right {
					}
				}
			}
		}
	}
</style>
